import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from 'nuka-carousel';
import styled from 'styled-components';

// change on screen size
const TWA_SLIDER_QUERY = graphql`
  query TWAImageListing {
    twaImages: allTwasliderJson {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED)
            }
          }
          title
          text
        }
      }
    }
  }
`;

const VignetteImage1 = styled.div`
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0px 0px 30px black;
  }
`;

const TWACarousel = () => (
  <StaticQuery
    query={TWA_SLIDER_QUERY}
    render={({ twaImages }) => (
      <div id="twacarousel">
        <Slider
          wrapAround
          autoplay
          pauseOnHover={false}
          autoplayInterval={5000}
        >
          {twaImages.edges.map(
            (edge) => (
              // <VignetteImage>
              <GatsbyImage
                image={edge.node.image.childImageSharp.gatsbyImageData}
              />
            )
            // </VignetteImage>
          )}
        </Slider>
      </div>
    )}
  />
);

export default TWACarousel;
