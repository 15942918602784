import React from 'react';
import styled from 'styled-components';

import MyBadLogo from '../images/mybad-logo.svg';
import TWAVideo from './TWAVideo';

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 20px;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    text-align: justify;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    text-align: justify;
  }
`;
const ProjectLogoWrapper = styled.div`
  text-align: left;
  flex: 2;
  align-self: top;
  font-size: 3rem;
  line-height: 3rem;
  padding-top: 20px;
  /* max-width:65%; */
  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2.2rem;
    align-self: center;
  }
  @media (max-width: 468px) {
    text-align: center;
    h3 {
      font-size: 1.8rem;
      line-height: 1.8rem;
      align-self: center;
    }
  } ;
`;

const ColumnWrapper = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  /* padding-right:40px; */
  @media (max-width: 768px) {
    flex: 1;
    width: 81vw;
  }
  @media (max-width: 400px) {
    width: 81vw;
    flex: 1;
  }
`;

const Angels = () => (
  <ProjectContainer>
    <ProjectLogoWrapper>
      <h3> TALKING WITH ANGELS </h3>
    </ProjectLogoWrapper>
    <ColumnWrapper>
      <div>
        <p className="dropcap">
          We're only a young company, but our first project pulled together some
          comedy legends:
        </p>
        <p>
          <ul>
            <li>Jim Moir (aka Vic Reeves)</li>
            <li>Jane Horrocks, </li>
            <li>Kayode Ewumi, </li>
            <li>John Thomson, </li>
            <li>Brian Blessed</li>
            <li>Julie Hesmondhalgh</li>
          </ul>
          Written and directed by Nick Wild we have developed this out both as a
          TV series and a feature film and are currently pitching.
        </p>
        <p> Here is the trailer... enjoy</p>
      </div>
      <TWAVideo />

      <div>
        <p> Please contact us for a link to the full project and bible</p>
        <h3>SYNOPSIS</h3>
        <p>
          Wandering through the streets of Northern England dressed in a blue
          velvet suit, Frank is not your traditional angel. Overworked and
          underpaid he has one final mission to complete his "tour of duty" and
          one last soul to save.
        </p>
        <p>
          With his 8G celestially powered smartphone failing to pick up a
          network connection to access his mission details he decides to kill
          some time and go to the theatre to see{' '}
          <strong>
            "Talking with Angels - A Psychic Evening with Bernard De La Reine."{' '}
          </strong>
        </p>
        <p>
          But all is not what it seems with Bernard, his spirit guide has long
          since departed him and now he has enlisted his disillusioned wife
          Cheryl to scam the audience. As the show starts to fall apart Frank
          steps in to help and has soon set Bernard up for his own TV show that
          will change his life forever.
        </p>
        <p>But was it chance that threw them together or was it destiny?</p>
      </div>
    </ColumnWrapper>
  </ProjectContainer>
);

export default Angels;
