import React from 'react';
import styled from 'styled-components';
import { Link, scroller } from 'react-scroll';

import rocketshipHero from '../images/rocketship-hero.svg';
import rocketshipHeroSmall from '../images/rocketship-hero-sm.svg';
import RocketShip from './RocketShip';
import DownArrow from '../images/downarrow.svg';

const HeroWrapper = styled.div`
        background-image: url(${rocketshipHero});
        background-position: right; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @media (max-aspect-ratio: 133/100) {
                background-image: url(${rocketshipHeroSmall});
                justify-content: space-between;
        }
`;

const Strapline = styled.div`
        font-family: Dancing Script, cursive;
        color: whitesmoke;
        font-weight: 400;
        font-size: 1.75rem;
        text-align: center;
        background-color: #230d49c0;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        min-height: 120px;
        padding-left: 30px;
        padding-right: 30px;
        h2 {
                font-weight: 700;
                font-size: 2rem;
        }
        @media (max-width: 768px) {
                font-size: 1.2rem;
                line-height: 1.5rem;
                min-height: 100px;
        }
`;

const DownArrowWrapper = styled(Link)`
        align-self: center;
        color: white;
        cursor: pointer;
        padding-bottom: 30px;
        text-align: center;
        justify-self: end;
        @media (max-width: 1023px) {
                display: none;
        }
`;

const Hero = () => (
        <HeroWrapper>
                <RocketShip />
                <DownArrowWrapper activeClass="active" to="mybadcarousel" smooth="true" duration={500}>
                        <img src={DownArrow} alt="Down Arrow" style={{ maxWidth: '40%' }} />
                        {/* Test */}
                </DownArrowWrapper>
                <Strapline>
                        <h2>Welcome to Rocketship!</h2>
                        <div>
                                Purveyors of high quality comedy products to discerning ladies and gentlemen everywhere.
                        </div>
                </Strapline>
        </HeroWrapper>
);
export default Hero;
