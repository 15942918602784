import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-bottom: 40px;
  @media (max-width: 968px) {
    padding-left: 60px;
    padding-right: 60px;
    text-align: justify;
  }
  @media (max-width: 480px) {
    padding-left: 32px;
    padding-right: 32px;
    text-align: justify;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.4rem;
  transform: rotate(-5deg);
  margin-bottom: -15px;
`;

const AboutUs = () => (
  <Container>
    <Title>About Us</Title>
    <p className="dropcap">
      Rocketship Productions was founded by writer /director Nick Wild and
      leading UK Casting Director Nicci Topping shortly after the devastating
      Giggle Famine in 1746. They vowed to never let this happen again - not on
      their watch, no sirree bob!
    </p>
    <p>
      Rocketship focus on comedy-drama and comedy for digital, film and TV,
      working with some of the best established stars and new talent in UK
      comedy, and live in hope that people chuckle along with what they produce.
    </p>
    {/* </div> */}
  </Container>
);

export default AboutUs;
