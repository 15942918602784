import React from 'react';
import styled from 'styled-components';
import MyBadLogo from '../images/mybad-logo.svg';

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0px 40px 0px;
  @media (max-width: 1365px) {
    padding: 20px 60px 40px 60px;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-left: 60px;
    padding-right: 60px;
    text-align: justify;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    text-align: justify;
  }
`;
const ProjectLogoWrapper = styled.div`
  text-align: center;
  flex: 2;
  font-size: 3rem;
  padding-top: 20px;
  padding-right: 40px;
  max-width: 65%;
  @media (max-width: 768px) {
    align-self: center;
    padding-right: 0px;
  }
`;

const MyBad = () => (
  <ProjectContainer>
    <ProjectLogoWrapper>
      <img src={MyBadLogo} alt="MyBad! Logo" />
    </ProjectLogoWrapper>
    <div style={{ flex: 5 }}>
      <p className="dropcap">
        Hot off the launchpad: we have just finished shooting a teaser for{' '}
        <strong>MyBad!</strong> starring Richard Blackwood, Jake Canuso, Will
        Mellor and John Thomson as 90s boy band sensation MyBad! and Sally
        Lindsay as their manager Marie.
      </p>
      <p>
        MyBad! was written and directed by Nick Wild and also features Paul
        Chuckle, John Culshaw, Nicole Barber-Lane, Rachel Knowles and Caprice
        amongst many others.
      </p>
      <p> Watch this space for commissioning updates!</p>
      {/* <p style={{ fontWeight: '700', fontSize: '1.6rem', marginBottom: '5px' }}> */}
      <h3> SYNOPSIS</h3>
      {/* </p> */}
      <p style={{ fontWeight: '700', marginTop: '2px' }}>
        <i>
          "Always Remember", "Back A’Cha", "Talk To The Hand" and "It Ain't You
          It's Me Babe"
        </i>
      </p>
      <p>
        ...are songs that shaped a generation in the 90’s. But it is 24 years
        since MyBad! broke a million teenage hearts when they split up and the
        intervening years have not been good to Gaz, Davey Dave, Tasty Flava and
        Stewie.
      </p>
      <p>
        After their previous manager squandered all their money on failed film
        tax schemes they are now all officially S to the K to the I to the N to
        the T - well maybe not all of them! Much to their chagrin fifth band
        member, Wayne Young, has actually done very well thank you very much and
        is now a Hollywood superstar!
      </p>
      <p>
        So when Marie, pub cleaner and former party planner for adult toy
        company Marie Winters, persuades the Fab Four to reform with her as
        their manager they have to try and put all their old grudges and years
        of festering resentment behind them to make it work.
      </p>
      <p style={{ fontWeight: '700' }}>
        MyBad! is a character driven story about 4 middle-aged men who discover
        lost friendship, that rivalries still run deep and that leather no
        longer suits a 20 stone man with a comb-over. They also discover that
        behind every resurgent pop star combo is a powerful woman who is capable
        of anything.
      </p>
      <p> Please contact us for a link to the full project and bible</p>
    </div>
  </ProjectContainer>
);

export { ProjectContainer, ProjectLogoWrapper };
export default MyBad;
