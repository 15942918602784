import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Slider from 'nuka-carousel';
import styled from 'styled-components';

// change on screen size
const MYBAD_SLIDER_QUERY = graphql`
  query MyBadImageListing {
    myImages: allMybadsliderJson {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED)
            }
          }
          title
          text
        }
      }
    }
  }
`;

const VignetteImage = styled.div`
  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 0px 0px 30px black;
  }
`;

const MBCarousel = () => (
  <StaticQuery
    query={MYBAD_SLIDER_QUERY}
    render={({ myImages }) => (
      <div id="mybadcarousel">
        <Slider
          wrapAround
          autoplay
          pauseOnHover={false}
          autoplayInterval={5000}
        >
          {myImages.edges.map((edge) => (
            <VignetteImage>
              <GatsbyImage
                image={edge.node.image.childImageSharp.gatsbyImageData}
              />
            </VignetteImage>
          ))}
        </Slider>
      </div>
    )}
  />
);

export default MBCarousel;
