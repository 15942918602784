import React from 'react';

const Video = ({ videoSrcURL, videoTitle, videoWidth, ...props }) => (
  <iframe
    src={videoSrcURL}
    title={videoTitle}
    width={videoWidth}
    height={videoWidth / 1.7}
    // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allow="autoplay; fullscreen"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
  />
);

export default Video;
