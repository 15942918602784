import React from 'react';
import styled from 'styled-components';

const TextHome = styled.div`
  max-width: 1000px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  font-size: 20px;
  p {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  @media (max-width: 849px) {
    font-size: 19px;
  }
`;

export default TextHome;
