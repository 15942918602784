import React from 'react'
import styled from 'styled-components'
import Obfuscate from "react-obfuscate";
import Logo from '../images/rocketship-logo-circle.svg'

// import TwitterFeed from './TwitterFeed'
// import logo from '../images/logo.png';

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #230d49;
  color: white;
  padding: 40px 30px 30px 40px;
  max-height:300px;

  @media all and (max-width: 767px) {
    flex-direction: column;
    justify-content:flex-start;
    /* height:auto; */
    min-height:700px;
  }
`

const FooterText = styled.div`
  padding-bottom: 20px;
  p {
    margin-bottom: 0px;
    font-size: 0.9rem;
    line-height: 1.4rem;
    margin-top: 0px;

  }
  h4 {
    margin-bottom: 0px;
    font-size: 1rem;
  }
`

const LogoWrapper = styled.div`
flex:2;
padding:0px 40px 40px 2px;
@media (max-width: 480px) {
  padding:0px 60px 0px 60px;
}
`


const Footer = () => (
  <FooterWrapper>
    <LogoWrapper>
      <img 
      src={Logo} 
      alt="Rocketship Productions" 
      style={{maxHeight:'70%'}}/>
    </LogoWrapper>
    <div style={{flex:'5'}}>
      <h3 className="">Contact Us!</h3>
      <FooterText>
        {/* <p>Twitter: @rocketshipproductions</p> */}
        <p>Email:<Obfuscate 
        email="blastoff@rocketship.productions"
        headers={{
          subject: "Email From Rocketship Website",
          body: "Hello Rocketeers!"
        }} /></p>
      </FooterText>
      <FooterText>
        <h4>Address</h4>
        <p>The Media Centre</p>
        <p>7 Northumberland Street</p>
        <p>Huddersfield</p>
        <p>West Yorkshire</p>
        <p>HD1 1RL</p>
      </FooterText>
    </div>
  </FooterWrapper>
)

export default Footer
