import React from 'react';
// import styled from 'styled-components';
import { Link, scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';

import { Layout, SEO } from '../components';
import Hero from '../components/Hero';
import MyBadCarousel from '../components/MyBadCarousel';
import TWACarousel from '../components/TWACarousel';
import MyBad from '../components/MyBad';
import Angels from '../components/Angels';
import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';
import TextHome from '../components/styles/TextHome';

const Home = () => (
  <Layout>
    <SEO title="RocketShip Productions" />
    <Hero />
    <MyBadCarousel />
    <TextHome>
      <MyBad />
    </TextHome>
    <TWACarousel />
    <TextHome>
      <Angels />
    </TextHome>
    <TextHome>
      <AboutUs />
    </TextHome>
    <Footer />
  </Layout>
);

export default Home;
